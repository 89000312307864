import React from "react";

const Cargando = () => {
    return (
        <div className="bg-white py-5 my-auto">
            <div className="container">
                <div className="d-flex flex-row justify-content-evenly align-items-center flex-wrap col-12 col-lg-12 mx-auto mb-75">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cargando;