import React from 'react';
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className="container-bg">
            <div className="row height">
                <div style={{background: 'linear-gradient(0deg, rgba(25, 25, 25, 0.5), rgba(25, 25, 25, 0.5)), url('  + window.location.origin + '/img/fondo-home.jpg)'}} className="d-flex align-items-center justify-content-center col-6 first-bg">
                    <div className="first-bg-text col-lg-6">
                        <p>Sociedad gerente de fondos comunes de inversión del grupo TPCG</p>
                    </div>
                </div>
                <Link to={"/productos"}
                    className="d-flex text-decoration-none align-items-center justify-content-center col-6 second-bg">
                    <div className="second-bg-text">
                        <p>Fondos</p>
                    </div>
                </Link>
            </div>
            <div className="container py-5">
                <div className="d-flex flex-row justify-content-evenly flex-wrap intro-text">
                    <div className="col-12 col-lg-6 pe-lg-3 texto-justificado">
                        <p>Somos una compañía especializada en la gestión del patrimonio de clientes institucionales, empresas e individuos de altos ingresos que confían en nuestra experiencia y en los valores del grupo. Southern Trust inició sus actividades en el año 2012 y se encuentra entre las mayores administradoras independientes de Fondos Comunes de Inversión de la Argentina.  </p>
                        <p>Contamos con un equipo de portfolio managers de amplia trayectoria que aplica un proceso disciplinado para optimizar los rendimientos de las carteras de nuestros clientes y un equipo comercial profesional que permite a Southern Trust comprender los intereses y objetivos de inversión de nuestros clientes </p>
                    </div>
                    <div className="col-12 col-lg-6 ps-lg-3 texto-justificado">
                        <p>para así encontrar la combinación de activos que cumpla con sus expectativas. </p>
                        <p>Transitamos este camino, gracias a la confianza que nos otorgan inversores, asesores financieros, agentes colocadores, proveedores y bancos custodios. </p>
                        <p>Ofrecemos instrumentos para el manejo de tesorería y múltiples fondos desde los más conservadores a fondos con estrategias más agresivas e innovadoras de impacto holístico, tales como nuestro fondo de soja SMR o nuestro fondo ST ESG.</p>
                    </div>
                </div>
            </div>
            <div className="servicios-ppales pb-5">
            <h1 className="text-center text-verde mb-5">Nuestra filosofia</h1>
                <div className="container d-flex justify-content-center justify-content-lg-between flex-wrap text-center">
                    <div className="flex-column col-lg-3">
                        <p className="fw-bold servicios-ppales-title">Comunicación abierta</p>
                        <p className="fw-light servicios-ppales-text">Escuchamos a nuestros clientes y nuestro trabajo está orientado a satisfacer sus necesidades.</p>
                    </div>
                    <div className="flex-column col-lg-3">
                        <p className="fw-bold servicios-ppales-title">Responsabilidad</p>
                        <p className="fw-light servicios-ppales-text">Gestionamos con precisión oportunidades y riesgos.</p>
                    </div>
                    <div className="flex-column col-lg-3">
                        <p className="fw-bold servicios-ppales-title">Integridad</p>
                        <p className="fw-light servicios-ppales-text">Procuramos la mayor transparencia en la información que brindamos. Actuamos de forma honesta y diligente.</p>
                    </div>
                    <div className="flex-column col-lg-3">
                        <p className="fw-bold servicios-ppales-title">Trabajo en equipo</p>
                        <p className="fw-light servicios-ppales-text">Creemos en la colaboración como modelo de acción y de crecimiento.</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;
