import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Home from "./components/home/Home";
import Contacto from "./components/contacto/Contacto";
import Nosotros from "./components/nosotros/Nosotros";
import Distribuidores from "./components/distribuidores/Distribuidores";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Productos from "./components/productos/Productos";
import Legales from './components/legales/Legales';

function App() {

    const [ productoVCP, setProductoVCP ] = useState();

    const getProductoVCP = async () => {
        try {
            const respuesta = await axios.get(window.location.origin + '/api/productost/getproductbyid/vcp')
            //const respuesta = await axios.get('https://southern-trust.azurewebsites.net/api/productost/getproductbyid/vcp')

            //const respuesta = await axios.get('http://test.stfondos.com/api/productost/getproductbyid/vcp')

            if (respuesta != null) {
                setProductoVCP(respuesta.data);
            }
        } catch (error) {
            setProductoVCP(null);
        }
    }

    useEffect(() => {
        getProductoVCP()
    }, [])


    return (
        <Router>
            <Header productoVCP={productoVCP}/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/contacto" component={Contacto}/>
                <Route exact path="/nosotros" component={Nosotros}/>
                <Route exact path="/productos" component={Productos}/>
                <Route exact path="/legales" component={Legales}/>
                <Route exact path="/distribuidores" component={Distribuidores}/>
            </Switch>
            <Footer productoVCP={productoVCP}/>
        </Router>
    );
}

export default App;
