import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Header = (props) => {

    $(document).ready(function () {
        $('.nav-link').click(function () {
            var windowsize = $(window).width();
            if (windowsize < 993) {
                $('.navbar-toggler').click();
                $(".nav-item").find(".active").removeClass("active");
                $(this).addClass("active");
            }
        });
        $('.navbar-brand').click(function () {
            var windowsize = $(window).width();
            var collapsed = $('.collapsed').length
            if (windowsize < 993 && collapsed == 0) {
                $('.navbar-toggler').click();
                $(".nav-item").find(".active").removeClass("active");
                $(this).addClass("active");
            }
        });
    });

    return (
        <Fragment>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-lg container-fluid container-mobile">
                    <Link className="navbar-brand" to={"/"}>
                        <img src={`${window.location.origin}/img/ST_LogoPrincipal.svg`} className="logo-header" alt="Logo de Southern Trust" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-lg-0">
                            <li className="nav-item mx-lg-1">
                                <a href={props.productoVCP != null ? props.productoVCP.urlPowerBi : null} className="nav-link menu-item mi-cuenta" target="_blank">VCP</a>
                            </li>
                            <li className="nav-item mx-lg-1">
                                <a href="https://cliente.stfondos.com/VFHOME/frwLogin.aspx" className="nav-link menu-item mi-cuenta" target="_blank">Mi cuenta</a>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link to={"/productos"} className="nav-link menu-item">Productos</Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link to={"/distribuidores"} className="nav-link menu-item">Distribuidores de
                                    fondos</Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link to={"/nosotros"} className="nav-link menu-item">Nosotros</Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link to={"/legales"} className="nav-link menu-item">Legales</Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link to={"/contacto"} className="nav-link menu-item">Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid second-nav d-none d-lg-block">
                <ul className="container d-flex flex-row mb-auto justify-content-start flex-wrap">
                    <Link to={"/productos"} className="nav-link">Productos</Link>
                    <Link to={"/distribuidores"} className="nav-link">Distribuidores de fondos.</Link>
                    <Link to={"/nosotros"} className="nav-link">Nosotros</Link>
                    <Link to={"/legales"} className="nav-link">Legales</Link>
                    <Link to={"/contacto"} className="nav-link">Contacto</Link>
                </ul>
            </div>
        </Fragment>
    );
};

export default Header;