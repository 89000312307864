import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery'

const Footer = (props) => {
    return (
        <Fragment>
            <div className="mt-75" />
            <div className="footer-z-index" />
            <div className="footer-bg py-5">
                <div className="container">
                    <div className=" d-flex justify-content-between text-light">
                        <div className="flex-column col-6 col-lg-3">
                            <img src={`${window.location.origin}/img/ST_LogoPrincipal_White.svg`} className="logo-footer" />
                            <p>Sociedad gerente de fondos comunes de inversión S.A. <b>AAPIC No. 31</b></p>
                        </div>
                        <a onClick={() => $(window).scrollTop(0)} href="https://linkedin.com" target="_blank" className="linkedin-font pe-0">in</a>
                    </div>
                    <div className=" d-flex justify-content-between footer-nav">
                        <ul className="ps-0 flex-lg-row flex-column d-flex justify-content-start flex-wrap">
                            <Link onClick={() => $(window).scrollTop(0)} to={"/productos"} className="ps-lg-0">Productos</Link>
                            <Link onClick={() => $(window).scrollTop(0)} to={"/distribuidores"}>Distribuidores de fondos</Link>
                            <Link onClick={() => $(window).scrollTop(0)} to={"/nosotros"}>Nosotros</Link>
                            <Link onClick={() => $(window).scrollTop(0)} to={"/legales"}>Legales</Link>
                            <Link onClick={() => $(window).scrollTop(0)} to={"/contacto"}>Contacto</Link>
                        </ul>
                        <ul className="flex-lg-row flex-column d-flex justify-content-start justify-content-lg-end flex-wrap text-end">
                            <a onClick={() => $(window).scrollTop(0)} href={props.productoVCP != null ? props.productoVCP.urlPowerBi : null} className="pe-0" target="_blank">VCP</a>
                            <a onClick={() => $(window).scrollTop(0)} href="https://cliente.stfondos.com/VFHOME/frwLogin.aspx" target="_blank" className="pe-0">Mi cuenta</a>
                        </ul>
                    </div>
                    <div className="footer-text text-white">
                        <p>La composición de la cartera de cada fondo común de inversión y las comisiones de los mismos están a disposición de los inversores y público en general en las oficinas de Southern Trust S.G.F.C.I.S.A.
                            Las inversiones en cuotas de los fondos no constituyen depósitos en Banco de Valores SA &nbsp;<Link onClick={() => $(window).scrollTop(0)} className="links" to={"/legales"}>Ver mas...</Link>
                        </p>
                    </div>
                    <div>
                        <a onClick={() => $(window).scrollTop(0)} href="https://www.cafci.org.ar/" target="_blank" className="pe-0 me-1">
                            <img src={`${window.location.origin}/img/legales/cafci.png`} alt="Logo de CAFCI" className="logo-legales px-1" />
                        </a>
                        <a onClick={() => $(window).scrollTop(0)} href="https://www.cnv.gov.ar/SitioWeb/FondosComunesInversion/" target="_blank" className="pe-0">
                            <img src={`${window.location.origin}/img/legales/cnv.png`} alt="Logo de CNV" className="logo-legales px-1" />
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
