import React, { Fragment, useEffect, useState } from 'react';
import Producto from './Producto';
import Checkboxes from './Checkboxes'
import axios from 'axios'
import Cargando from '../layout/Cargando';

const Productos = () => {

    const [productos, setProductos] = useState([]);
    const [productosFiltrados, setProductosFiltrados] = useState([]);

    const [error, setError] = useState();

    const [cargando, setCargando] = useState(true);

    const getProductos = async () => {
        try {
            const respuesta = await axios.get(window.location.origin + '/api/productost/getproducts')
            //const respuesta = await axios.get('https://southern-trust.azurewebsites.net/api/productost/getproducts')
            //const respuesta = await axios.get('http://test.stfondos.com/api/productost/getproducts')
            if (respuesta != null) {
                setProductos(respuesta.data);
                setProductosFiltrados(respuesta.data)
                setCargando(false);
                setError(null);
            }
        } catch (error) {
            setCargando(false);
            setError("Ha ocurrido un error en el servidor. Intente nuevamente");
        }
    }


    useEffect(() => {

        getProductos();

    }, [])


    const [selected, setSelected] = useState({
        tipoDeFondo: [],
        perfilDeRiesgo: []
    });


    const listTipoDeFondo = [
        {
            id: 0,
            name: 'tipodefondo',
            label: 'Todos',
            value: ''
        },
        {
            id: 1,
            name: 'estrategia',
            label: 'Estrategia',
            value: 'Estrategia'
        },
        {
            id: 2,
            name: 'tesoreria',
            label: 'Tesorería',
            value: 'Tesorería'
        },
        {
            id: 3,
            name: 'normativos',
            label: 'Normativos',
            value: 'Normativos'
        }
    ];

    const listPerfilDeRiesgo = [
        {
            id: 0,
            name: 'perfilderiesgo',
            label: 'Todos',
            value: ''
        },
        {
            id: 1,
            name: 'conservador',
            label: 'Conservador',
            value: 'Conservador'
        },
        {
            id: 2,
            name: 'moderado',
            label: 'Moderado',
            value: 'Moderado'
        },
        {
            id: 3,
            name: 'agresivo',
            label: 'Agresivo',
            value: 'Agresivo'
        }
    ];

    const handleFilters = (checkboxState, key) => {
        const logic = 'AND';
        const newFilters = { ...selected };
        newFilters[key] = checkboxState;

        const tieneTipoDeFondo = newFilters.tipoDeFondo.length > 0;
        const tienePerfilDeRiesgo = newFilters.perfilDeRiesgo.length > 0;
        const hasFilters = tieneTipoDeFondo || tienePerfilDeRiesgo;
        const filtrarTipoDeFondo = (module) =>
            newFilters.tipoDeFondo.includes('') ||
            newFilters.tipoDeFondo.includes(module.tipoDeFondo);
        const filtrarPerfilDeRiesgo = (module) =>
            newFilters.perfilDeRiesgo.includes('') || newFilters.perfilDeRiesgo.includes(module.perfilDeRiesgo);

        const filteredProducts = productos.filter(
            logic === 'OR'
                ? (m) => !hasFilters || filtrarTipoDeFondo(m) || filtrarPerfilDeRiesgo(m) // OR
                : (m) =>
                    !hasFilters ||
                    ((!tieneTipoDeFondo || filtrarTipoDeFondo(m)) && (!tienePerfilDeRiesgo || filtrarPerfilDeRiesgo(m))) // AND
        );
        setProductosFiltrados(filteredProducts);
        setSelected(newFilters);
    };

    if (error) {
        return (
            <Fragment>
                <div className="container d-flex flex-column align-items-center my-auto">
                    <h1 className="display-5 text-center">{error}</h1>
                </div>
                <div className="mb-75">
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {cargando ?
                <Cargando />
                :
                <div className="bg-white py-5 mb-auto">
                    <div className="container">
                        <h1 className="text-verde mb-4">Productos</h1>
                        <hr />
                        <div className="d-flex justify-content-between flex-wrap">
                            <div className="flex-column d-flex">
                                <p className="fondos-text">Tipo de fondo:</p>
                                <div className="flex-row d-flex justify-content-start fondos-text-tipos flex-wrap">
                                    <Checkboxes
                                        list={listTipoDeFondo}
                                        handleFilters={(checkboxState) =>
                                            handleFilters(checkboxState, 'tipoDeFondo')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex-column d-flex mt-3 mt-lg-0 ">
                                <p className="fondos-text">Perfil de riesgo:</p>
                                <div className="flex-row d-flex justify-content-start fondos-text-tipos flex-wrap">
                                    <Checkboxes
                                        list={listPerfilDeRiesgo}
                                        handleFilters={(checkboxState) =>
                                            handleFilters(checkboxState, 'perfilDeRiesgo')
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="pb-4" />
                        <div className="card-deck d-flex flex-wrap justify-content-center justify-content-lg-start mb-75">
                            {productosFiltrados && productosFiltrados.length !== 0 ?
                                productosFiltrados.map((productoFiltrado, index) =>
                                    <Producto key={index} producto={productoFiltrado} index={index} />
                                )
                                : <h2 className="text-center d-flex align-items-center my-auto">No existen productos</h2>
                            }
                        </div>
                    </div>
                </div>
            }

        </Fragment>
    );
};

export default Productos;
