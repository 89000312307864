import React, {useState} from 'react';

const Distribuidores = () => {

    const [distribuidores, setDistribuidores] = useState([
        {
            url: 'https://www.alchemyvalores.com/',
            alt: 'Logo de Alchemy Valores S.A.',
            src: '/img/distribuidores/alchemy.png'
        }, {
            url: 'https://www.aurum.com,ar/',
            alt: 'Logo de Aurum S.A.',
            src: '/img/distribuidores/aurum.png'
        },
        {
            url: 'https://www.bagnardiycia.com.ar/',
            alt: 'Logo de Bagnardi y Cia S.A.',
            src: '/img/distribuidores/bagnardi.png'
        },
        {
            url: 'https://www.balanz.com/',
            alt: 'Logo de Balanz',
            src: '/img/distribuidores/balanz.png'
        },{
            url: 'https://www.bavsa.com/',
            alt: 'Logo de Buenos Aires Valores S.A.',
            src: '/img/distribuidores/bavsa.jpg'
        },{
            url: 'https://www.bibank.com/',
            alt: 'Logo de BiBank S.a.',
            src: '/img/distribuidores/bibank.png'
        },
        {
            url: 'https://www.criteria.com.ar/',
            alt: 'Logo de Criteria',
            src: '/img/distribuidores/criteria.jpg'
        },
        {
            url: 'https://davalores.com.ar/',
            alt: 'Logo de DA Valores S.A.',
            src: '/img/distribuidores/da-valores.png'
        },{
            url: 'https://dealfs.com.ar/',
            alt: 'Logo de DFS S.A.',
            src: '/img/distribuidores/dfs.png'
        },
        {
            url: 'https://www.estructurasfinancieras.com.ar/',
            alt: 'Logo de EFIRE',
            src: '/img/distribuidores/efire.png'
        },
        {
            url: 'https://gmcvalores.com.ar/',
            alt: 'Logo de GMC Valores S.A.',
            src: '/img/distribuidores/gmc.png'
        },
        {
            url: 'https://integrarinversiones.com.ar/',
            alt: 'Logo de Integrar S.A.',
            src: '/img/distribuidores/integrar.svg'
        },
        {
            url: 'https://www.lbo.com.ar/',
            alt: 'Logo de ibo',
            src: '/img/distribuidores/ibo.png'
        },
        {
            url: 'https://www.leivahnos.com.ar/home-bursatil/',
            alt: 'Logo de Leiva Hnos S.A.',
            src: '/img/distribuidores/leiva.png'
        },
        {
            url: 'https://mercadofci.rofex.com.ar/',
            alt: 'Logo de Matba Rofex',
            src: '/img/distribuidores/matbarofex.png'
        },
        {
            url: 'https://www.max.capital/',
            alt: 'Logo de Max Valores S.A.',
            src: '/img/distribuidores/max-valores.png'
        },
        {
            url: 'https://www.nasini.com.ar/home',
            alt: 'Logo de Nasini S.A.',
            src: '/img/distribuidores/nasini.jpg'
        },
        {
            url: 'http://www.optionsecurities.com.ar/',
            alt: 'Logo de Option Securities S.A.',
            src: '/img/distribuidores/option-securities.jpg'
        },
        {
            url: 'https://www.petrini.com.ar/',
            alt: 'Logo de Petrini Valores S.A',
            src: '/img/distribuidores/petrini-valores.png'
        },
        {
            url: 'https://portfolioinvestment.com.ar/',
            alt: 'Logo de Portfolio Investment S.A.',
            src: '/img/distribuidores/portfolio-investment.png'
        },
        {
            url: 'https://portfoliopersonal.com/',
            alt: 'Logo de PP Inversiones S.A.',
            src: '/img/distribuidores/ppi.png'
        },
        {
            url: 'http://www.provinciabursatil.com.ar/',
            alt: 'Logo de Provincia Bursatil S.A.',
            src: '/img/distribuidores/provincia-bursatil.png'
        },
        {
            url: 'https://www.roagro.com.ar/valores/',
            alt: 'Logo de Roagro S.A.',
            src: '/img/distribuidores/roagro.png'
        },
        {
            url: 'http://www.rosariovalores.com.ar/',
            alt: 'Logo de Rosario Valores S.A.',
            src: '/img/distribuidores/rosval.png'
        },
        {
            url: 'https://schweber.com.ar/',
            alt: 'Logo de Schweber Securities S.A.',
            src: '/img/distribuidores/schweber-securities.png'
        },
        {
            url: 'https://www.smsv.com.ar/websmsv/inicio/alyc-an%20-titulos',
            alt: 'Logo de Sociedad Militar Seguro de Vida - SMSV',
            src: '/img/distribuidores/smsv.png'
        },
        {
            url: 'https://sycinversiones.com/',
            alt: 'Logo de SyC Inversiones S.A.',
            src: '/img/distribuidores/syc-inversiones.png'
        },
        {
            url: 'https://www.tpcgvalores.com.ar/es',
            alt: 'Logo de TPCG Valores S.A.U.',
            src: '/img/distribuidores/tpcg.jpg'
        },
        {
            url: 'https://valcereal.com/',
            alt: 'Logo de Valcereal S.A.',
            src: '/img/distribuidores/valcereal.svg'
        },
 {
            url: 'https://www.winsa.com.ar/',
            alt: 'Logo de WIN Securities S.A.',
            src: '/img/distribuidores/win.jpg'
        } ])

    return (
        <div className="bg-white py-5 mb-auto">
            <div className="container">
                <h1 className="text-verde mb-4">Distribuidores</h1>
               <div
                    className="d-flex flex-row justify-content-evenly align-items-center flex-wrap col-12 col-lg-12 mx-auto mb-75">
                    {distribuidores && distribuidores.length !== 0 ?
                        distribuidores.map((distribuidor, index) =>
                                <a href={distribuidor.url} key={index} className="distribuidor d-flex align-items-center" target="_blank">
                                    <img src={`${window.location.origin + distribuidor.src}`} className="w-100" alt={distribuidor.alt}/>
                                </a>
                        )
                        : null
                    }
                </div>
              </div>
        </div>
    );
};

export default Distribuidores;
