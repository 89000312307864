import React from 'react';
import $ from 'jquery'

const Persona = ({persona, index}) => {
    return (
        <div>
            <a href={persona.linkedin} target="_blank" className="card m-2 persona-card text-decoration-none">
                <img src={`${window.location.origin}/img/nosotros/${persona.url}.jpg`} className="card-img-top mx-auto" alt="..."/>
                <div className="card-body text-center text-uppercase">
                    <h5 className="card-title fw-bold">{persona.nombreCompleto}</h5>
                    <p className="card-text fw-lighter posicion-text">{persona.posicion}</p>
                </div>
            </a>
            {/*<div className="card m-2 persona-card"  data-bs-toggle="modal" data-bs-target={"#modal" + index}>*/}
            {/*    <img src={persona.url} className="card-img-top mx-auto" alt="..."/>*/}
            {/*    <div className="card-body text-center text-uppercase">*/}
            {/*        <h5 className="card-title fw-bold">{persona.nombreCompleto}</h5>*/}
            {/*        <p className="card-text fw-lighter posicion-text">{persona.posicion}</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="modal persona-modal fade" id={"modal" + index} tabIndex="-1" aria-labelledby="exampleModalLabel"*/}
            {/*     aria-hidden="true">*/}
            {/*    <div className="modal-dialog modal-lg modal-dialog-scrollable">*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-header persona-modal-header">*/}
            {/*                <div className="d-flex flex-row justify-content-evenly flex-wrap w-75 my-4 mx-auto">*/}
            {/*                    <img src={persona.url} className="card-img-top radius-modal mb-2" alt="..."/>*/}
            {/*                    <div className="my-auto">*/}
            {/*                        <h4 className="modal-title fw-bold ms-auto me-0">{persona.nombreCompleto}</h4>*/}
            {/*                        <h5 className="posicion-modal-text" id="exampleModalLabel">{persona.posicion}</h5>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <button type="button" className="btn-close mb-auto" data-bs-dismiss="modal"*/}
            {/*                        aria-label="Close"/>*/}
            {/*            </div>*/}
            {/*            <div className="modal-body m-5">*/}
            {/*                {persona.descripcion && persona.descripcion.length !== 0 ?*/}
            {/*                    persona.descripcion.map((descripcion, index) =>*/}
            {/*                        <p key={persona.nombreCompleto+index}>{descripcion}</p>*/}
            {/*                    )*/}
            {/*                    : null*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Persona;
