import React from 'react';

const Legales = () => {

    return (
        <>
            <div className="mt-75"></div>
            <div className="nosotros-z-index"></div>
            <div className="bg-white py-5 mb-auto">
                <div className="container ">
                    <h1 className="text-verde mb-4">Legales</h1>
                    <div className="d-flex flex-row justify-content-evenly flex-wrap intro-text">
                        <div className="col-12 pe-lg-3 texto-justificado">
                            <p>La composición de la cartera de cada fondo común de inversión y las comisiones de los mismos están a disposición de los inversores y público en general en las oficinas de Southern Trust S.G.F.C.I.S.A. Las inversiones en cuotas de los fondos no constituyen depósitos en Banco de Valores SA y Banco COMAFI SA, a los fines de la Ley de Entidades Financieras ni cuentan con ninguna de las garantías que tales depósitos a la vista o a plazo puedan gozar de acuerdo a la legislación y reglamentación aplicables en materia de depósitos en entidades financieras. Asimismo, Banco de Valores SA y Banco COMAFI SA, se encuentra impedido por normas del Banco Central de la República Argentina de asumir, tácita o expresamente, compromiso alguno en cuanto al mantenimiento, en cualquier momento, del valor del capital invertido, al rendimiento, al valor de rescate de las cuotapartes o al otorgamiento de liquidez a tal fin. Las inversiones en fondos comunes de inversión pueden estar sujetas a riesgo de inversión soberanos, comerciales, de tipo de cambio y a otros riesgos, incluyendo la posible pérdida de la inversión. Usted deberá tener presente que esta información no implica asesoramiento ni consejo de inversión de ningún tipo; por ello para tomar cualquier decisión de inversión resultará imprescindible consultar con sus asesores.</p>
                        </div>
                    </div>
                    <p className="fw-bold mb-0 servicios-ppales-title">Defensa al consumidor</p>
                    <p>
                        Para consultas y/o denuncias ingrese <a href="https://www.argentina.gob.ar/servicio/iniciar-un-reclamo-ante-defensa-de-las-y-los-consumidores" className="links" target="_blank">aquí</a>.
                    </p>
                    <p className="fw-bold mb-0 servicios-ppales-title">Protección de datos personales</p>
                    <p>
                        El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326 o bien, en cualquier momento, el retiro o bloqueo total o parcial de su nombre de la base de datos apersonándose en nuestras oficinas adjuntando copia de su DNI. LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
                    </p>
                    <p className="fw-bold mb-0 servicios-ppales-title">Código de protección al inversor</p>
                    <p>
                        Ingrese <a href={`${window.location.origin}/pdf/codigo-de-proteccion-al-inversor.pdf`} className="links" target="_blank">aquí</a>.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Legales;
