import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Contacto = () => {

    const contacotUrl = "";

    const [usuario, setUsuario] = useState({
        nombre: '',
        email: '',
        mensaje: ''
    });
    const onChange = e => {
        const { name, value } = e.target
        setUsuario({ ...usuario, [name]: value })
    }

    useEffect(() => {

    }, [])

    const enviarContacto = async (datos) => {

        const respuesta = await axios.post('https://prod-04.brazilsouth.logic.azure.com:443/workflows/7d65ae401cce454f985c884d3a03dc77/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=S3c96ooRHDi5vpVNrv-qRD1EFgn8Z8WrVDOricW_dBI', datos)
        if (respuesta != null)
            alert("Nos pondremos en contacto con usted");
        else
            alert("error");
            
    }

    const onSubmit = (e) => {
        e.preventDefault();
        enviarContacto(usuario);
        setUsuario({
            nombre: '',
            email: '',
            mensaje: ''
        })

    }

    return (

        <div className="container-fluid px-0">
            <div className="d-flex flex-wrap justify-content-center">
                <div className="bg-veige py-5 mb-auto col-12 col-lg-6">
                    <form onSubmit={onSubmit}>
                        <div className="col-10 col-lg-8 mx-auto">
                            <div className="d-flex flex-column pb-5 justify-content-center">
                                <h1 className="text-verde mb-4">Contacto</h1>
                                <p>Escribinos tu consulta y te responderemos a la brevedad.</p>
                                <input className="form-control p-3 input-contact-form"
                                    type="text"
                                    placeholder="Nombre y Apellido"
                                    name="nombre"
                                    value={usuario.nombre}
                                    onChange={onChange}
                                    required></input>
                                <input className="form-control p-3 input-contact-form"
                                    id="contactEmail"
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    value={usuario.email}
                                    onChange={onChange}
                                    required></input>
                                <textarea className="form-control p-3 input-contact-form-textarea"
                                    rows="5"
                                    placeholder="Mensaje"
                                    name="mensaje"
                                    value={usuario.mensaje}
                                    onChange={onChange} 
                                    required/>
                                <button type="submit"
                                    className="btn btn-success my-2 btn-color-verde"
                                    text="Enviar"
                                >Enviar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="bg-white pt-5 mb-auto justify-content-center col-lg-6 col-12">
                    <div className="bg-white col-10 col-lg-8 mx-auto texto-justificado">
                        <img src={`${window.location.origin}/img/ST_LogoPrincipal_SinTexto.svg`} className="logo-contacto" />
                        <p className="fw-bold contacto-logo-text">Southern Trust S.G.F.C.I.S.A.</p>
                        <div className="contacto-text">
                            <p>Ortiz de Ocampo 3302 - Módulo IV - Piso 3 - C1425dfe - C.A.B.A.</p>
                            <p>+54 11 7700 3600</p>
                            <p>0800-345-5639</p>
                            <p className="pb-4">infost@stfondos.com</p>
                            <p>Inscripta en la I.G.J. el 17/06/2008 bajo el Registro
                                Nº 12050, Libro 40 de Sociedades por Acciones.</p>
                            <p>CUIT 33710608909</p>
                            <p className="pb-4">AIF CNV del AAPIC</p>
                            <p className="pb-4">Dirección General de Defensa y Protección al Consumidor
                                Para consultas y/o denuncias Ingrese <a href="https://www.argentina.gob.ar/servicio/iniciar-un-reclamo-ante-defensa-de-las-y-los-consumidores" target="_blank" className="links">aquí</a>.</p>
                            <a href="https://www.linkedin.com/company/southern-trust/" target="_blank" className="linkedin-font--black">in</a>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.104668580246!2d-58.371100584770325!3d-34.601514680460085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3353c62d48fef%3A0x37649cdeb37639d7!2sBouchard%20547%2C%20C1001%20CABA!5e0!3m2!1ses-419!2sar!4v1631887560621!5m2!1ses-419!2sar" className="maps" allowFullScreen={true} loading="lazy" />
                </div>
            </div>
        </div>

    );
};

export default Contacto;
