import React, {useState} from 'react';
import Persona from "./Persona";

const Nosotros = () => {

    const [presidentes, setPresidentes] = useState([
        {
            nombreCompleto: 'Sigrid Ana Tolaba',
            posicion: 'Presidente',
            url: 'sigrid-ana-tolaba',
            descripcion: [
                'CEO de Southern Trust desde 2012. Durante casi 20 años, se desempeñó como CEO y Presidente del fondo local de capitales canadienses Toronto Trust, líder en rendimiento de mercados emergentes (Micropal/Lipper AS).',
                'Fue también Directora de la Cámara Argentina de Fondos Comunes de Inversión y Presidente de la comisión de marketing.',
                'Sigrid es Contadora Pública de la Universidad de Buenos Aires. Estudió Economía y Finanzas en el CEMA y cuenta con una Maestría en dirección de negocios del Instituto de Altos Estudios (IAE) Universidad Austral.'
            ],
            linkedin: 'https://www.linkedin.com/in/sigridtolaba/'
        },
        {
            nombreCompleto: 'Jorge Alvarez de la Viesca',
            posicion: 'Vice Presidente',
            url: 'jorge-alvarez-de-la-viesca',
            descripcion: [
                'Es Gerente del Área Legal del Grupo, Director Titular de TPCG Valores S.A., Director Titular de Southern Trust Sociedad Gerente de Fondos Comunes de Inversión S.A. y Oficial de Cumplimiento de las Normas de Prevención de Lavado de Dinero de ambas sociedades mencionadas.',
                'Con anterioridad se desempeñó como asesor legal externo desde la fundación del Grupo TPCG.',
                'Jorge es abogado de la Universidad de Buenos Aires.'
            ],
            linkedin: 'https://www.linkedin.com/company/southern-trust/'
        },
    ])

    const [directores, setDirectores] = useState([
        {
            nombreCompleto: 'Daniel Iubatti',
            posicion: 'Director Ejecutivo',
            url: 'daniel-iubatti',
            descripcion: [
                'Dirigió la Mesa de Cambios y el Sector de Trading y Ventas en el Grupo Mildesa.',
                'Socio Fundador de TPCG Capital S.A., TPCG Valores S.A., TPCG Financial Services Agentes de Valore S.A., TPCG Advisors S.A. y Southern Trust SGFCISA.'
            ],
            linkedin: 'https://www.linkedin.com/company/southern-trust/'
        },
        {
            nombreCompleto: 'Claudio Acherandio',
            posicion: 'Director Ejecutivo',
            url: 'claudio-acherandio',
            descripcion: [
                'Es Presidente de TPCG Advisors SA. Socio fundador de TPCG Capital S.A., TPCG Valores S.A., TPCG Financial Services Agente de Valores S.A., TPCG Advisors S.A. y Southern Trust SGFCI S.A.',
                'Con anterioridad se desempeñó como analista corporativo e integrante del equipo de financiamiento de proyectos de inversión de Banco Río de la Plata S.A. Trabajó en Banco Mildesa S.A. como oficial de cuentas corporativas senior.',
                'Claudio es graduado en Ciencias Económicas de la Universidad Católica Argentina'
            ],
            linkedin: 'https://www.linkedin.com/company/southern-trust/'
        },
        {
            nombreCompleto: 'Ileana Aiello',
            posicion: 'Director Ejecutivo',
            url: 'ileana-aiello',
            descripcion: [
                'Socia fundadora de TPCG Capital S.A., TPCG Valores S.A., TPCG Financial Services Agente de Valores S.A., TPCG Advisors S.A. y Southern Trust SGFCI S.A. Actualmente es Vice Presidente de TPCG Financial Services Agente de Valores S.A.',
                'Con anterioridad estuvo a cargo del Departamento de Instrumentos Internacionales de Renta Fija de Banco Mildesa S.A. Responsable de desarrollar la relación comercial con la comunidad financiera internacional. ',
                'Ileana es licenciada en Administración de Empresas de la Universidad de Buenos Aires.'
            ],
            linkedin: 'https://www.linkedin.com/in/ileana-aiello-b465909a/'
        }
    ])


    return (
        <>
            <div className="mt-75"></div>
            <div className="nosotros-z-index"></div>
            <div className="bg-white py-5 mb-auto">
                <div className="container">
                    <h2 className="directorio-text text-center py-3">Directorio</h2>
                    <div className="d-flex flex-column justify-content-evenly mx-auto mb-75">
                        <div className="d-flex flex-row mx-auto justify-content-center flex-wrap">
                            {presidentes && presidentes.length !== 0 ?
                                presidentes.map((presidente, index) =>
                                    <Persona key={index} persona={presidente}
                                             index={presidente.nombreCompleto.replace(/ /g, '')}/>
                                )
                                : null
                            }
                        </div>
                        <div className="d-flex flex-row mx-auto justify-content-center flex-wrap">
                            {directores && directores.length !== 0 ?
                                directores.map((director, index) =>
                                    <Persona key={index} persona={director}
                                             index={director.nombreCompleto.replace(/ /g, '')}/>
                                )
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nosotros;
