import React, { Fragment } from 'react';
import $ from 'jquery';

const Producto = ({ producto, index }) => {

    const onClickProducto = () => {
        if (producto.urlPowerBi) {
            $('#' + index).append('<iframe width="600" height="373.5" src="' + producto.urlPowerBi + '" frameBorder="0" allowFullScreen={true}/>')
        }
    }

    return (
        <Fragment>
            {producto.perfilDeRiesgo ?
                <div>
                    <div onClick={onClickProducto} style={{ backgroundImage: `url(${window.location.origin}/img/${producto.perfilDeRiesgo.toLowerCase()}.png)` }} className="card producto-card mb-3 p-3 text-white me-3 " data-bs-toggle="modal" data-bs-target={"#modal" + index}>
                        {producto.titulo}
                    </div>
                    <div className="modal producto-modal fade" id={"modal" + index} tabIndex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header producto-modal-header px-5 py-3">
                                    <div className="">
                                        <h5 className="modal-title producto-modal-title" id="exampleModalLabel">{producto.titulo}</h5>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <img src={`${window.location.origin}/img/ST_LogoPrincipal_White.svg`} className="logo-header" alt="Logo de Southern Trust" />
                                        {/*<button type="button" className="btn-close my-auto" data-bs-dismiss="modal"*/}
                                        {/*        aria-label="Close"/>*/}
                                    </div>
                                </div>
                                <div className="modal-body mx-auto" id={index}>

                                </div>
                                <div className="modal-footer justify-content-center">
                                    {producto.reglamento ?
                                        <a href={producto.reglamento} type="button" target="_blank" className="btn producto-modal-boton mx-2 px-5">Reglamento
                                        </a>
                                        : null
                                    }
                                    {producto.url ?
                                        <a href={producto.url} type="button" target="_blank" className="btn producto-modal-boton mx-2 px-5">Imprimir
                                        </a>
                                        : null
                                    }
                                    {producto.url2 ?
                                        <a href={producto.url2} type="button" target="_blank" className="btn producto-modal-boton mx-2 px-5">
                                            Informe trimestral
                                        </a>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </Fragment>
    );
};

export default Producto;
