import { useState } from 'react';

const Checkboxes = ({ list, handleFilters }) => {
    const [checkedArray, setCheckedArray] = useState([0]);

    const handleToggle = (checkboxId, previousState) => {
        const currentIndex = previousState.indexOf(checkboxId);
        let newCheckedArray = [...previousState];
        if (currentIndex === -1) {
            if (checkboxId === 0) {
                newCheckedArray = [];
            } else {
                const filtered = newCheckedArray.filter((item) => item !== 0);
                newCheckedArray = [...filtered];
            }
            newCheckedArray.push(checkboxId);
        } else {
            newCheckedArray.splice(currentIndex, 1);
        }
        return newCheckedArray.sort((a, b) => a - b);
    };

    const onChangeHandler = (checkboxId) => {
        const newState = handleToggle(checkboxId, checkedArray);
        setCheckedArray(newState);
        // Update this checked information into Parent Component
        handleFilters(newState.map((id) => list[id].value));
    };

    return list.map((item, index) => {
        return (
            <div key={index} className="px-1 my-lg-0 my-1">
                <input
                    className='chk-btn'
                    type="checkbox"
                    id={item.name}
                    checked={checkedArray.indexOf(item.id) !== -1}
                    onChange={() => onChangeHandler(item.id)}
                />
                <label className="fw-bold btn checkbox-label" htmlFor={item.name}><span className={"dot align-middle " + item.label.toLowerCase()}></span> {item.label}</label>
            </div>
        );
    });
};

export default Checkboxes;
